import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Loading() {
  return (
    <CircularProgress sx={{
      position: 'fixed',
      top: '50%',
      right: '50%',
      zIndex: 1500,
    }} />
  );
}

export default Loading;
