import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Loading from '../../components/Misc/Loading';
import config from '../../config';

const AppPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  useEffect(() => {
    const redirectTo = `${config.APP_URL}${window.location.href.split('/app')[1]}`;
    window.location.replace(redirectTo);
  }, []);

  return (
    <>
      <Helmet title={`App | ${title}`} />
      <Loading />
    </>
  );
};

AppPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export default AppPage;

export const appPageQuery = graphql`
  query AppQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
